@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --foreground-rgb: 0, 0, 0;
    --background-start-rgb: 214, 219, 220;
    --background-end-rgb: 255, 255, 255;
    --background-hex: #f6f8fa;
}

@layer base {
    :root {
        --colour-primary-300: 160 130 243;
        --colour-primary-400: 148 115 242;
        --colour-primary-500: 137 100 241;
        --colour-primary-600: 119 74 244;
        --colour-primary-700: 67 56 202;
        --colour-primary-800: 55 48 163;
        --colour-primary-900: 49 46 129;
        --colour-secondary-300: 255 255 255;
        --colour-secondary-400: 255 255 255;
        --colour-secondary-500: 255 255 255;
        --colour-secondary-600: 255 255 255;
        --colour-secondary-700: 255 255 255;
        --colour-secondary-800: 255 255 255;
        --colour-secondary-900: 255 255 255;
    }
}

@media (prefers-color-scheme: dark) {
    :root {
        --foreground-rgb: 255, 255, 255;
        --background-start-rgb: 255, 255, 255;
        --background-end-rgb: 255, 255, 255;
    }
}

* {
    font-family: Roobert, sans-serif;
}

/* Fonts */
@font-face {
    font-family: "Gustavo";
    font-style: normal;
    font-weight: 700;
    src:
        local("Gustavo"),
        url("./fonts/Gustavo_Bold.woff") format("woff");
}

@font-face {
    font-family: "Gustavo";
    font-style: normal;
    font-weight: 500;
    src:
        local("Gustavo"),
        url("./fonts/Gustavo_Medium.woff") format("woff");
}

@font-face {
    font-family: "Gustavo";
    font-style: normal;
    font-weight: 400;
    src:
        local("Gustavo"),
        url("./fonts/Gustavo_Regular.woff") format("woff");
}

@font-face {
    font-family: "Gustavo";
    font-style: normal;
    font-weight: 300;
    src:
        local("Gustavo"),
        url("./fonts/Gustavo_Thin.woff") format("woff");
}

@font-face {
    font-family: "Roobert";
    font-style: normal;
    font-weight: normal;
    src:
        local("Roobert"),
        url("./fonts/RoobertRegular.woff") format("woff");
}

@font-face {
    font-family: "Roobert";
    font-style: italic;
    font-weight: normal;
    src:
        local("Roobert Italic"),
        url("./fonts/RoobertRegularItalic.woff") format("woff");
}

@font-face {
    font-family: "Roobert";
    font-style: normal;
    font-weight: 300;
    src:
        local("Roobert"),
        url("./fonts/RoobertLight.woff") format("woff");
}

@font-face {
    font-family: "Roobert";
    font-style: italic;
    font-weight: 300;
    src:
        local("Roobert Light Italic"),
        url("./fonts/RoobertLightItalic.woff") format("woff");
}

@font-face {
    font-family: "Roobert";
    font-style: normal;
    font-weight: 500;
    src:
        local("Roobert"),
        url("./fonts/RoobertMedium.woff") format("woff");
}

@font-face {
    font-family: "Roobert";
    font-style: italic;
    font-weight: 500;
    src:
        local("Roobert"),
        url("./fonts/RoobertMediumItalic.woff") format("woff");
}

@font-face {
    font-family: "Roobert";
    font-style: normal;
    font-weight: 600;
    src:
        local("Roobert"),
        url("./fonts/RoobertSemiBold.woff") format("woff");
}

@font-face {
    font-family: "Roobert";
    font-style: normal;
    font-weight: 700;
    src:
        local("Roobert"),
        url("./fonts/RoobertBold.woff") format("woff");
}

body {
    color: #1c1c1e;
    background: var(--background-hex);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    /* font-family: 'Gustavo', Roobert, sans-serif; */
}

h2 {
    color: #1c1c1e;
    /* font-family: 'Gustavo', Roobert, sans-serif; */
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.64px;
}

img:-moz-broken {
    display: none;
}

img[src="error"]:-moz-broken {
    display: initial;
}

input,
select,
textarea {
    outline: none;
}

select {
    appearance: none !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
    background-position: right 0.5rem center;
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;
    padding-right: 2.5rem;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
}

input[type="radio"] {
    cursor: pointer !important;
}

input[type="radio"] + label {
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid var(--gray-300, #d0d5dd);
    background: var(--White, #fff);

    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

input[type="radio"]:checked + label {
    cursor: pointer;
    color: #774af4;
    border-radius: 8px;
    border: 1px solid var(--primary-600-300, #d6bbfb);
    background: var(--White, #fff);

    /* Shadow/xs focused 4px primary-100 */
    box-shadow:
        0px 0px 0px 4px #f4ebff,
        0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

button:disabled {
    opacity: 0.3;
    cursor: not-allowed;
}
